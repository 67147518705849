/* You can add global styles to this file, and also import other style files */
@import "~jsgantt-improved/dist/jsgantt.css"; //Angular gantt styles

form {
  &.for-detail {
    input,
    select,
    textarea {
      &:disabled {
        color: #000 !important;
      }
    }
  }
}

.clr-form-vertical {
  .clr-form-control {
    align-items: unset;
  }
  .clr-input-wrapper {
    input,
    select {
      width: 100%;
    }
  }
  .clr-select-wrapper {
    width: 100%;
    select {
      width: 100%;
    }
  }
  .clr-textarea-wrapper {
    textarea {
      width: 100%;
    }
  }
}

.badge {
  &.badge-white {
    background: #fff;
    color: #000;
    border: 1px solid #8c8c8c;
  }
}

.monospaced {
  font-family: monospace;
}